import React, { useState } from 'react';
import './Carousel.css';
import Card from "./CardComponent"
import img1 from "./../../assets/images/WhatsApp-Image-2023-06-03-at-12.40.14-PM.png";
import img2 from "./../../assets/images/home-1.webp";
import img3 from "./../../assets/images/hos.png";
import img4 from "./../../assets/images/loane.webp"
import img5 from "./../../assets/images/credit.jpg"


const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const Englishcards = [
    { title: 'Loans as Assets: Enhancing Your Financial Growth', content: '', url:'/blog1.html',src:img1 },
    { title: 'Transforming Dead Assets into Fruitful Assets with Loans', content: '' ,url:'/blog2.html',src:img2},
    { title: 'Good GST Turnover: A Gateway to Loan Opportunities', content: '' ,url:'/blog3.html',src:img3},
    { title: 'Unlock Growth with Loans for Non-Agriculture Farmhouses', content: '' ,url:'/blog4.html',src:img4},
    { title: 'Do not delay your EMI for better credit score', content: '',url:'/blog5.html',src:img5 },
     ];
  const hindiCards = [
    { "title": "ऋण के रूप में संपत्तियाँ: आपके वित्तीय विकास को बढ़ावा दें", "content": "", "url": "/blog1.html", "src": img1 },
    { "title": "मृत संपत्तियों को ऋण के माध्यम से फलदायी संपत्तियों में बदलें", "content": "", "url": "/blog2.html", "src": img2 },
    { "title": "अच्छा जीएसटी टर्नओवर: ऋण अवसरों का द्वार", "content": "", "url": "/blog3.html", "src": img3 },
    { "title": "गैर-कृषि फार्महाउस के लिए ऋण के साथ विकास को खोलें", "content": "", "url": "/blog4.html", "src": img4 },
    { "title": "बेहतर क्रेडिट स्कोर के लिए अपनी ईएमआई में देरी न करें", "content": "", "url": "/blog5.html", "src": img5 }
  ];
  let noOfCards = window.screen.availWidth<769 ?1:4
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + noOfCards) % Englishcards.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - noOfCards + Englishcards.length) % Englishcards.length);
  };

  const getVisibleCards = () => {
    const endIndex = currentIndex + noOfCards;
    let cards = localStorage.getItem("language")==="Hindi" ? hindiCards : Englishcards
    return cards.slice(currentIndex, endIndex).concat(cards.slice(0, Math.max(endIndex - cards.length, 0)));
  };

  return (
    <div className="cardcarousel">
      <button onClick={prevSlide} className="carousel-button carousel-button-left">
        ◀
      </button>
      <div className="carousel-cards-container">
      
        {getVisibleCards().map((card, index) =>
        <Card imageSrc={card.src} title={card.title} description={card.content} url={card.url}   />)
}
      </div>
      <button onClick={nextSlide} className="carousel-button carousel-button-right">
        ▶
      </button>
    </div>
  );
};

export default Carousel;
