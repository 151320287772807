// src/Services.js
import React, { useState } from 'react';
import './Services.css';
import houseIcon from './../../assets/images/house-icon.png'; // Replace with the actual path to your house icon image

const englishservicesData = {
  "Home loan": {
    title: "Home Loan",
    content: (
      <>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          DNFinS offers secured home loans for purchasing or renovating residential property, using the property as collateral. Our loans come with several benefits and flexible options to suit your needs.
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Features & Benefits
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Tax benefits on principal and interest</li>
          <li>Lower interest rates</li>
          <li>Long repayment tenure up to 30 years</li>
          <li>No prepayment penalty</li>
          <li>Balance transfer facility to reduce EMI</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Types of Home Loans
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Normal Home Loan for purchasing property</li>
          <li>Plot + Construction Loan for buying land and building</li>
          <li>Home Loan Refinance to refinance existing loans</li>
          <li>Pre Approved Home Loan for pre-approved funding</li>
          <li>Home Overdraft for an overdraft facility</li>
          <li>Home Loan Balance Transfer to transfer existing loans</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Eligibility Criteria
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Age: 23 to 75 years</li>
          <li>Loan Amount: ₹8 lakh to ₹100 crore</li>
          <li>CIBIL Score: 650+</li>
          <li>Loan to Value (LTV): Up to 75%</li>
          <li>Tenure: Up to 30 years</li>
          <li>Interest Rate: 8.65% to 12%</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Required Documents
        </h2>
        <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For Salaried:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and Passport size Photographs of all Borrowers.</li>
        <li>Latest 2 yrs Form 16, latest 3 months Salary Slips, Appointment Letter, Company ID Card, Increment Letter.</li>
        <li>Updated 1 yr bank statement in which salary is being credited.</li>
        <li>Sanction Letter and complete SOA of all running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For SENP:
      </h3>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Proprietorship firm:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of firm.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and firm.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Partnership firm:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Pan Card and Partnership Deed of firm.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of firm.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers and partnership firm.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and firm.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Pvt. Ltd. / Ltd. Company:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Pan Card, MOA & AOA, Company Profile, LOD & LOS, Licenses if any applicable.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of company.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers and company.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and company.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For SEP:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Qualification Degree, Certificates, Licenses (if any applicable).</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers.</li>
        <li>Latest 1 yr S/A bank statement of all borrowers.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>
      </>
    )
  }
,
"Loan against property": {
  title: "Loan Against Property",
  content: (
    <>
      <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
        {/* Loan Against Property */}
      </h1>

      <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
     
      It is the loan that you get from the bank against the mortgage of your property. This type of loans come under the category of secured loan. This type of loan is sanctioned against the asset pledged as collateral.This asset can either be an owned land, a house or any other commercial premises. The asset remains as a collateral with the lender until the entire loan against property amount is repaid.

Banks and HFCs offer loan against property starting at low interest rates for tenures of up to 25 years.

The loan amount for LAP can go up to 70% of the property’s market value, depending on the lender and the credit profile of the borrowers.

Many lenders also offer Lease Rental Discounting (LRD) facility, which allows consumers to avail loan by pledging the rental receipts of their tenants
       </p>
       <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Required Documents
        </h2>
        <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For Salaried:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and Passport size Photographs of all Borrowers.</li>
        <li>Latest 2 yrs Form 16, latest 3 months Salary Slips, Appointment Letter, Company ID Card, Increment Letter.</li>
        <li>Updated 1 yr bank statement in which salary is being credited.</li>
        <li>Sanction Letter and complete SOA of all running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For SENP:
      </h3>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Proprietorship firm:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of firm.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and firm.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Partnership firm:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Pan Card and Partnership Deed of firm.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of firm.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers and partnership firm.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and firm.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        In case of Pvt. Ltd. / Ltd. Company:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Pan Card, MOA & AOA, Company Profile, LOD & LOS, Licenses if any applicable.</li>
        <li>Udyam and GST Reg. Certificate along with latest 1 yr GST Return Form 3B of company.</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers and company.</li>
        <li>Latest 1 yr C/A bank statement and S/A bank statement of all borrowers and company.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>

      <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For SEP:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Qualification Degree, Certificates, Licenses (if any applicable).</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers.</li>
        <li>Latest 1 yr S/A bank statement of all borrowers.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>
     
    </>
  )
},
  "MSME Loans": {
  title: "MSME Loans",
  content: (
    <>
      

      <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
        MSME (Micro, Small and Medium Enterprises) Loans are financial products specifically designed to aid the growth and development of small businesses. These loans provide essential funding to MSMEs, enabling them to expand operations, invest in new technologies, manage working capital, and cover various business-related expenses.
      </p>

      <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        Key Features of MSME Loans
      </h2>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li><strong>Flexible Loan Amount:</strong> MSME loans cater to diverse business needs, offering amounts ranging from a few lakhs to several crores based on business requirements and eligibility criteria.</li>
        <li><strong>Competitive Interest Rates:</strong> These loans typically come with attractive interest rates, which are influenced by the business’s credit score, financial health, and prevailing market conditions.</li>
        <li><strong>Unsecured Loans:</strong> Many MSME loans are unsecured, requiring no collateral. However, larger loan amounts might necessitate some form of security.</li>
        <li><strong>Flexible Repayment Tenure:</strong> Borrowers can choose from various repayment options, with tenures generally spanning from 1 to 5 years, allowing for manageable repayment schedules aligned with the business’s cash flow.</li>
        <li><strong>Government Support:</strong> Numerous government schemes, like the Credit Guarantee Fund Trust for Micro and Small Enterprises (CGTMSE), provide additional support and benefits, facilitating easier access to loans for MSMEs.</li>
        <li><strong>Quick Disbursal:</strong> Lenders often ensure swift and straightforward loan disbursal processes, helping businesses access necessary funds promptly when needed.</li>
      </ul>

      <p style={{ fontSize: '16px', lineHeight: '1.6', marginTop: '20px', textAlign: 'left' }}>
        MSME Loans play a vital role in empowering small businesses to achieve sustainable growth and success. By providing crucial financial support, these loans help MSMEs enhance their competitiveness, improve infrastructure, and realize their long-term business objectives.
      </p>
    </>
  )
}
,
  "Working Capital": {
    title: "Working Capital",
    content: (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* Working Capital */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          Working Capital loans are short-term loans designed to help businesses manage their daily operational expenses. These loans provide immediate funds to cover various operational needs, such as inventory purchase, payroll, and other short-term liabilities. By leveraging working capital financing, businesses can maintain smooth operations and address cash flow gaps.
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Key Features of Working Capital Loans
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>Flexible Repayment:</strong> Working capital loans offer flexible repayment terms to suit the cash flow patterns of the business.</li>
          <li><strong>Quick Processing:</strong> Fast approval and disbursal processes ensure that funds are available when needed.</li>
          <li><strong>Usage:</strong> Funds can be used for a variety of short-term business needs, including inventory, salaries, and operational expenses.</li>
          <li><strong>Loan Amount:</strong> The loan amount is typically determined based on the business’s financial health and operational requirements.</li>
          <li><strong>Interest Rates:</strong> Competitive interest rates are available depending on the lender and the business's credit profile.</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Eligibility Criteria
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Business vintage of at least 1 year</li>
          <li>Positive cash flow and profitability</li>
          <li>Valid business registration and licenses</li>
          <li>Good credit history of the business and its owners</li>
          <li>Financial statements for the last 1-2 years</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Required Documents
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Business registration documents</li>
          <li>Recent bank statements</li>
          <li>Financial statements for the last 1-2 years</li>
          <li>Proof of business address</li>
          <li>Identity and address proof of business owners</li>
        </ul>

        <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        For SEP:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>KYC and passport size photographs of all Borrowers.</li>
        <li>Qualification Degree, Certificates, Licenses (if any applicable).</li>
        <li>Latest 3 yrs complete set of financials along with tax audit report of all Borrowers.</li>
        <li>Latest 1 yr S/A bank statement of all borrowers.</li>
        <li>Sanction Letter of all previous running loans.</li>
        <li>Form 26 AS, Rent Agreement with rental credit bank statement of latest 1 yr. (If Rental Income is there).</li>
        <li>Complete back chain property documents with latest tax receipt and MAP.</li>
      </ul>
      </>
    )
  },
  "Specialized Property Loans": {
    title: "Specialized Property Loans",
    content: (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* Specialized Property Loans */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
        Our specialized mortgage services deliver quick credit decisions, flexible funding and high touch personal attention.

This is for those wishing to purchase purpose –built properties such as recreational parks, accommodation complexes, hospitality venues, etc.

DNFinS will ensure not only do you lock in the best loan, but that you walk away from the experience feeling confident and prepare for the future of your property.

Each of our specialized mortgage programs features unique characteristics, ranging from loan size and term to various interest rates and credit score qualifications.

Scroll posters of all type of specialized property ( hotels, hospitals, warehouses, resorts, collages, schools, malls,)
          </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Key Features of Specialized Property Loans
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>Customizable Solutions:</strong> Loans are tailored to fit specific property-related needs, including commercial property acquisitions and refinancings.</li>
          <li><strong>Flexible Terms:</strong> Offers flexible loan terms and repayment options to suit different financial situations.</li>
          <li><strong>Competitive Interest Rates:</strong> Access to competitive interest rates based on the property type and borrower's profile.</li>
          <li><strong>High Loan Amounts:</strong> Potential to secure large loan amounts depending on the value of the property and the financial standing of the borrower.</li>
          <li><strong>Expert Advice:</strong> Professional guidance and support throughout the loan application and approval process.</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Types of Specialized Property Loans
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Industrial Property
          </li>
          <li>Hospitals</li>
          <li>Hotels</li>
          <li>Schools</li>
          <li>Marriage Halls</li>
          <li>Warehouses</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Eligibility Criteria
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Property valuation by a certified appraiser</li>
          <li>Proof of income and financial stability</li>
          <li>Good credit history and financial track record</li>
          <li>Valid property documents and ownership proof</li>
          <li>Clear business plans or project outlines for property development</li>
        </ul>

      
      </>
    )
  },
  "Commercial Real Estate Loan": {
  title: "Commercial Real Estate Loan",
  content: (
    <>
      <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
        {/* Commercial Real Estate Loan */}
      </h1>

      <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
        A Commercial Real Estate Loan (CRE Loan) 
         considered a type of business loan that helps entrepreneurs, business owners, and other business entities in acquiring finance to buy machinery/equipment for various business purposes. This type of loan helps business entities in gaining more productivity while using new equipment and machinery.      </p>

      <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
      Benefits
      </h2>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>It helps to buy new equipment/ machinery for new or existing business.</li>
        <li>Used to refurbish, modify or change existing machinery/ equipment.</li>
        <li>To repair faulty machines or equipment or to upgrade.
        </li>
        <li>Flexible loan repayment options with easy EMI’s.
        </li>
        <li>Machinery loan for startups is an additional benefit for new businesses.</li>
        <li>Collateral free loans from selected NBFC’s, Small Finance Banks (SBFS), etc.</li>
      </ul>

      <p style={{ fontSize: '16px', lineHeight: '1.6', marginTop: '20px', textAlign: 'left' }}>
        Commercial Real Estate Loans provide businesses with the capital needed to invest in and develop their commercial properties. With flexible terms and competitive rates, these loans are essential for business growth and expansion.
      </p>

      <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Required Documents
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>KYC and passport size photograph of borrowers and firm or company.
          </li>
          <li>Latest 2 yrs complete set of financials and CA Certified tax audit report of borrowers (individual or firm or company).
          </li>
          <li>Latest 1 yr bank statement of borrowers.
          </li>
          <li>Quotations of Machinery to be purchased.
          </li>
          <li>Sanction letter of all running previous loans (If any).
          </li>
        </ul>
    </>
  )
}
,
  "Loan Portfolio Management Services ": {
    title: "Loan Portfolio Management Services (LPMS)",
    content: (
      <>
        

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left', marginLeft: '7px' }}>
       
        D N Financial Services’ Loan Portfolio Management Services (LPMS) will help you optimise your loan portfolio. Our comprehensive solutions assist you in managing and monitoring your loan portfolio effectively, lowering risk and maximising rewards. We provide vital insights to improve your lending strategies through powerful analytics and experienced coaching. Stay ahead of the curve with our LPMS, which is customised to your specific needs. D N Financial Services can help you streamline your loan portfolio administration. Investigate LPMS today to propel your financial success!

In this service we manage your existing loans to save you from various rises in interest amount and various features linked to the loan, ensuring maximum financial eligibility for future.

We help in reducing the financial burden by periodic monitoring and guiding your profile so that you can create your asset in future with optimism security.    </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Key Features of LPMS
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>Portfolio Optimization:</strong> Strategies to enhance the performance of your loan portfolio and maximize returns.</li>
          <li><strong>Risk Management:</strong> Identifying and mitigating potential risks associated with loan portfolios.</li>
          <li><strong>Performance Tracking:</strong> Monitoring and evaluating the performance of loans to ensure alignment with financial goals.</li>
          <li><strong>Customized Solutions:</strong> Tailored services to meet the specific needs of your loan portfolio, including restructuring and refinancing options.</li>
          <li><strong>Expert Advice:</strong> Professional guidance and support from experienced financial experts to optimize your portfolio management.</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Benefits of LPMS
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Improved management and efficiency of loan portfolios</li>
          <li>Enhanced financial performance and reduced costs</li>
          <li>Better risk assessment and mitigation strategies</li>
          <li>Increased transparency and control over loan assets</li>
          <li>Access to expert advice and advanced financial tools</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Why Choose Us?
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Proven track record of successful portfolio management</li>
          <li>Dedicated team of financial experts and analysts</li>
          <li>Comprehensive range of services to cover all aspects of loan portfolio management</li>
          <li>Commitment to delivering tailored solutions that meet your specific needs</li>
          <li>Focus on enhancing financial outcomes and optimizing performance</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Get Started
        </h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          Contact us today to learn more about our Loan Portfolio Management Services and how we can help you achieve better financial results with your loan portfolio.
        </p>
      </>
    )
  },
  "Unsecured Business Loans": {
    title: "Unsecured Business Loans",
    content: (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* Unsecured Business Loans */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          Unsecured Business Loans provide financing without requiring collateral. These loans are designed to support businesses with flexible funding options for various purposes, such as working capital, expansion, or operational costs. They offer a convenient solution for businesses that may not have significant assets to pledge as security.
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Key Features of Unsecured Business Loans
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>No Collateral Required:</strong> Access funds without needing to provide assets as security.</li>
          <li><strong>Fast Approval:</strong> Quick approval and disbursal process to meet urgent funding needs.</li>
          <li><strong>Flexible Uses:</strong> Funds can be used for a variety of business needs including expansion, working capital, and inventory.</li>
          <li><strong>Competitive Interest Rates:</strong> Attractive interest rates based on the borrower's credit profile and business performance.</li>
          <li><strong>Minimal Documentation:</strong> Simplified application process with minimal documentation requirements.</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Eligibility Criteria
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Business operation for at least 1 year</li>
          <li>Positive cash flow and financial stability</li>
          <li>Good credit score of the business owner(s)</li>
          <li>Valid business registration and licenses</li>
          <li>Recent financial statements and income proof</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Required Documents
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>Business registration documents</li>
          <li>Recent bank statements</li>
          <li>Financial statements for the last 1-2 years</li>
          <li>Proof of identity and address of business owners</li>
          <li>Tax returns for the last 1-2 years</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          Why Choose Our Unsecured Business Loans?
        </h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          Our Unsecured Business Loans offer a streamlined and flexible approach to securing funding for your business. With a fast approval process, minimal documentation, and competitive interest rates, we provide tailored financial solutions to support your business goals and growth ambitions.
        </p>
      </>
    )
  }
};
const hindiservicesData = {
  "गृह ऋण": {
    title: "गृह ऋण",
    content: (
      <>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          DNFinS आवासीय संपत्ति की खरीद या नवीनीकरण के लिए संपत्ति को संपार्श्विक के रूप में उपयोग करके सुरक्षित गृह ऋण प्रदान करता है। हमारे ऋण में कई लाभ और आपकी आवश्यकताओं के अनुरूप लचीले विकल्प शामिल हैं।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          विशेषताएँ और लाभ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>मूलधन और ब्याज पर कर लाभ</li>
          <li>कम ब्याज दरें</li>
          <li>30 वर्षों तक लंबी पुनर्भुगतान अवधि</li>
          <li>पूर्व भुगतान पर कोई दंड नहीं</li>
          <li>ईएमआई को कम करने के लिए बैलेंस ट्रांसफर सुविधा</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          गृह ऋण के प्रकार
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>संपत्ति खरीदने के लिए सामान्य गृह ऋण</li>
          <li>भूमि और भवन खरीदने के लिए प्लॉट + निर्माण ऋण</li>
          <li>मौजूदा ऋणों को पुनर्वित्त करने के लिए गृह ऋण पुनर्वित्त</li>
          <li>पूर्व-स्वीकृत फंडिंग के लिए पूर्व-स्वीकृत गृह ऋण</li>
          <li>ओवरड्राफ्ट सुविधा के लिए गृह ओवरड्राफ्ट</li>
          <li>मौजूदा ऋणों को स्थानांतरित करने के लिए गृह ऋण बैलेंस ट्रांसफर</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मानदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>आयु: 23 से 75 वर्ष</li>
          <li>ऋण राशि: ₹8 लाख से ₹100 करोड़ तक</li>
          <li>CIBIL स्कोर: 650+</li>
          <li>ऋण से मूल्य (LTV): 75% तक</li>
          <li>अवधि: 30 वर्षों तक</li>
          <li>ब्याज दर: 8.65% से 12% तक</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        वेतनभोगियों के लिए:
      </h3>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>सभी उधारकर्ताओं के केवाईसी और पासपोर्ट आकार के फ़ोटो।</li>
        <li>नवीनतम 2 वर्षों का फॉर्म 16, नवीनतम 3 महीनों की वेतन पर्ची, नियुक्ति पत्र, कंपनी आईडी कार्ड, वेतनवृद्धि पत्र।</li>
        <li>वेतन क्रेडिट की जा रही बैंक खाता की अद्यतन 1 वर्ष की बैंक स्टेटमेंट।</li>
        <li>सभी चल रहे ऋणों का स्वीकृति पत्र और पूरा SOA।</li>
        <li>फॉर्म 26 एएस, किराए की आय के लिए किराया समझौता और नवीनतम 1 वर्ष की बैंक स्टेटमेंट।</li>
        <li>संपत्ति के पूरी पिछली चेन दस्तावेज़, नवीनतम कर रसीद और मानचित्र।</li>
      </ul>

      <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        SENP के लिए:
      </h3>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        स्वामित्व फर्म के मामले में:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>सभी उधारकर्ताओं के केवाईसी और पासपोर्ट आकार के फ़ोटो।</li>
        <li>फर्म का उध्यम और जीएसटी पंजीकरण प्रमाणपत्र और नवीनतम 1 वर्ष का जीएसटी रिटर्न फॉर्म 3B।</li>
        <li>नवीनतम 3 वर्षों के पूर्ण वित्तीय विवरण, साथ ही सभी उधारकर्ताओं का टैक्स ऑडिट रिपोर्ट।</li>
        <li>नवीनतम 1 वर्ष का C/A बैंक स्टेटमेंट और सभी उधारकर्ताओं और फर्म का S/A बैंक स्टेटमेंट।</li>
        <li>सभी पिछले चल रहे ऋणों का स्वीकृति पत्र।</li>
        <li>फॉर्म 26 एएस, किराए की आय के लिए किराया समझौता और नवीनतम 1 वर्ष की बैंक स्टेटमेंट।</li>
        <li>संपत्ति के पूरी पिछली चेन दस्तावेज़, नवीनतम कर रसीद और मानचित्र।</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        साझेदारी फर्म के मामले में:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>सभी उधारकर्ताओं के केवाईसी और पासपोर्ट आकार के फ़ोटो।</li>
        <li>फर्म का पैन कार्ड और साझेदारी डीड।</li>
        <li>फर्म का उध्यम और जीएसटी पंजीकरण प्रमाणपत्र और नवीनतम 1 वर्ष का जीएसटी रिटर्न फॉर्म 3B।</li>
        <li>नवीनतम 3 वर्षों के पूर्ण वित्तीय विवरण, साथ ही सभी उधारकर्ताओं और साझेदारी फर्म का टैक्स ऑडिट रिपोर्ट।</li>
        <li>नवीनतम 1 वर्ष का C/A बैंक स्टेटमेंट और सभी उधारकर्ताओं और फर्म का S/A बैंक स्टेटमेंट।</li>
        <li>सभी पिछले चल रहे ऋणों का स्वीकृति पत्र।</li>
        <li>फॉर्म 26 एएस, किराए की आय के लिए किराया समझौता और नवीनतम 1 वर्ष की बैंक स्टेटमेंट।</li>
        <li>संपत्ति के पूरी पिछली चेन दस्तावेज़, नवीनतम कर रसीद और मानचित्र।</li>
      </ul>

      <h4 style={{ fontSize: '16px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
        प्रा. लि. / लि. कंपनी के मामले में:
      </h4>
      <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
        <li>सभी उधारकर्ताओं के केवाईसी और पासपोर्ट आकार के फ़ोटो।</li>
        <li>पैन कार्ड, MOA & AOA, कंपनी प्रोफाइल, LOD & LOS, यदि कोई लागू हो तो लाइसेंस।</li>
        <li>कंपनी का उध्यम और जीएसटी पंजीकरण प्रमाणपत्र और नवीनतम 1 वर्ष का जीएसटी रिटर्न फॉर्म 3B।</li>
        <li>नवीनतम 3 वर्षों के पूर्ण वित्तीय विवरण, साथ ही सभी उधारकर्ताओं और कंपनी का टैक्स ऑडिट रिपोर्ट।</li>
        <li>नवीनतम 1 वर्ष का C/A बैंक स्टेटमेंट और सभी उधारकर्ताओं और कंपनी का S/A बैंक स्टेटमेंट।</li>
        <li>सभी पिछले चल रहे ऋणों का स्वीकृति पत्र।</li>
        <li>फॉर्म 26 एएस, किराए की आय के लिए किराया समझौता और नवीनतम 1 वर्ष की बैंक स्टेटमेंट।</li>
        <li>संपत्ति के पूरी पिछली चेन दस्तावेज़, नवीनतम कर रसीद और मानचित्र।</li>
      </ul>

      </>)
  },

  "व्यक्तिगत ऋण": {
    title: "व्यक्तिगत ऋण",
    content: (
      <>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          DNFinS द्वारा पेश किया गया व्यक्तिगत ऋण आपकी वित्तीय आवश्यकताओं को पूरा करने के लिए एक आसान और तेज़ समाधान है। कोई संपार्श्विक की आवश्यकता नहीं है और हमारे पास लचीली पुनर्भुगतान विकल्प और प्रतिस्पर्धी ब्याज दरें हैं।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          विशेषताएँ और लाभ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>आपकी सभी वित्तीय आवश्यकताओं के लिए बहुउद्देशीय ऋण</li>
          <li>तेज़ स्वीकृति प्रक्रिया</li>
          <li>मात्र दस्तावेज़ीकरण</li>
          <li>लचीली पुनर्भुगतान विकल्प</li>
          <li>मासिक ईएमआई कम करने के लिए बैलेंस ट्रांसफर सुविधा</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मानदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>आयु: 23 से 60 वर्ष</li>
          <li>न्यूनतम मासिक आय: ₹15,000</li>
          <li>ऋण राशि: ₹1 लाख से ₹50 लाख तक</li>
          <li>CIBIL स्कोर: 750+</li>
          <li>अवधि: 1 से 5 वर्षों तक</li>
          <li>ब्याज दर: 12% से 24% तक</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>पहचान का प्रमाण: पैन कार्ड/आधार कार्ड</li>
          <li>पते का प्रमाण: आधार कार्ड/वोटर आईडी/पासपोर्ट</li>
          <li>आय का प्रमाण: नवीनतम वेतन पर्ची/आईटीआर</li>
          <li>बैंक स्टेटमेंट: नवीनतम 6 महीनों की बैंक स्टेटमेंट</li>
        </ul>
      </>
    ),
  },

  "व्यवसाय ऋण": {
    title: "व्यवसाय ऋण",
    content: (
      <>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          DNFinS द्वारा पेश किया गया व्यवसाय ऋण आपके व्यवसाय की वित्तीय आवश्यकताओं को पूरा करने के लिए एक आदर्श विकल्प है। हमारे पास विभिन्न प्रकार के व्यवसाय ऋण हैं जो आपके व्यवसाय के विकास में मदद करेंगे।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          विशेषताएँ और लाभ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>आपकी व्यवसाय की सभी वित्तीय आवश्यकताओं के लिए ऋण</li>
          <li>कम ब्याज दरें</li>
          <li>लचीली पुनर्भुगतान विकल्प</li>
          <li>मात्र दस्तावेज़ीकरण</li>
          <li>मासिक ईएमआई कम करने के लिए बैलेंस ट्रांसफर सुविधा</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मानदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>आयु: 25 से 65 वर्ष</li>
          <li>वार्षिक कारोबार: ₹10 लाख से ₹100 करोड़ तक</li>
          <li>ऋण राशि: ₹10 लाख से ₹10 करोड़ तक</li>
          <li>CIBIL स्कोर: 700+</li>
          <li>अवधि: 1 से 10 वर्षों तक</li>
          <li>ब्याज दर: 12% से 20% तक</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>पहचान का प्रमाण: पैन कार्ड/आधार कार्ड</li>
          <li>पते का प्रमाण: आधार कार्ड/वोटर आईडी/पासपोर्ट</li>
          <li>आय का प्रमाण: आईटीआर/जीएसटी रिटर्न</li>
          <li>बैंक स्टेटमेंट: नवीनतम 12 महीनों की बैंक स्टेटमेंट</li>
        </ul>
      </>
    ),
  },
  "कार्यशील पूंजी": {
    "title": "कार्यशील पूंजी",
    "content": (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* कार्यशील पूंजी */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          कार्यशील पूंजी ऋण अल्पकालिक ऋण होते हैं जो व्यवसायों को उनके दैनिक परिचालन खर्चों का प्रबंधन करने में मदद करते हैं। ये ऋण विभिन्न परिचालन आवश्यकताओं को पूरा करने के लिए तुरंत फंड प्रदान करते हैं, जैसे इन्वेंट्री खरीद, वेतन, और अन्य अल्पकालिक देनदारियां। कार्यशील पूंजी वित्तपोषण का उपयोग करके, व्यवसाय अपने परिचालन को सुचारू रूप से चला सकते हैं और नकदी प्रवाह की खाई को पाट सकते हैं।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          कार्यशील पूंजी ऋण की मुख्य विशेषताएँ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>लचीला पुनर्भुगतान:</strong> कार्यशील पूंजी ऋण व्यवसाय के नकदी प्रवाह पैटर्न के अनुसार लचीली पुनर्भुगतान शर्तें प्रदान करते हैं।</li>
          <li><strong>त्वरित प्रक्रिया:</strong> त्वरित अनुमोदन और वितरण प्रक्रियाएं सुनिश्चित करती हैं कि जब भी फंड की आवश्यकता हो, वे उपलब्ध हो जाएं।</li>
          <li><strong>उपयोग:</strong> फंड का उपयोग विभिन्न अल्पकालिक व्यावसायिक आवश्यकताओं, जैसे इन्वेंट्री, वेतन, और परिचालन खर्चों के लिए किया जा सकता है।</li>
          <li><strong>ऋण राशि:</strong> ऋण राशि आमतौर पर व्यवसाय की वित्तीय स्थिति और परिचालन आवश्यकताओं के आधार पर निर्धारित की जाती है।</li>
          <li><strong>ब्याज दरें:</strong> प्रतिस्पर्धात्मक ब्याज दरें उपलब्ध होती हैं, जो ऋणदाता और व्यवसाय के क्रेडिट प्रोफाइल पर निर्भर करती हैं।</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मापदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>कम से कम 1 वर्ष का व्यावसायिक अनुभव</li>
          <li>सकारात्मक नकदी प्रवाह और लाभप्रदता</li>
          <li>वैध व्यवसाय पंजीकरण और लाइसेंस</li>
          <li>व्यवसाय और उसके मालिकों का अच्छा क्रेडिट इतिहास</li>
          <li>पिछले 1-2 वर्षों के वित्तीय विवरण</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>व्यवसाय पंजीकरण दस्तावेज़</li>
          <li>हालिया बैंक स्टेटमेंट</li>
          <li>पिछले 1-2 वर्षों के वित्तीय विवरण</li>
          <li>व्यवसाय के पते का प्रमाण</li>
          <li>व्यवसाय के मालिकों की पहचान और पते का प्रमाण</li>
        </ul>

        <h3 style={{ fontSize: '18px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          एसईपी के लिए:
        </h3>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>सभी उधारकर्ताओं के केवाईसी और पासपोर्ट साइज फोटो।</li>
          <li>योग्यता डिग्री, प्रमाण पत्र, लाइसेंस (यदि कोई लागू हो)।</li>
          <li>सभी उधारकर्ताओं के नवीनतम 3 वर्षों के संपूर्ण वित्तीय विवरण और कर ऑडिट रिपोर्ट।</li>
          <li>सभी उधारकर्ताओं के नवीनतम 1 वर्ष के बचत खाता बैंक स्टेटमेंट।</li>
          <li>सभी पिछले चल रहे ऋणों का स्वीकृति पत्र।</li>
          <li>फॉर्म 26 एएस, किराया समझौता और नवीनतम 1 वर्ष का किराया क्रेडिट बैंक स्टेटमेंट। (यदि किराये की आय हो)।</li>
          <li>संपत्ति दस्तावेजों की पूरी बैक चेन के साथ नवीनतम कर रसीद और मानचित्र।</li>
        </ul>
      </>
    )
  },
  "विशिष्ट संपत्ति ऋण": {
    "title": "विशिष्ट संपत्ति ऋण",
    "content": (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* विशिष्ट संपत्ति ऋण */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          हमारे विशिष्ट मॉर्गेज सेवाएँ त्वरित क्रेडिट निर्णय, लचीला वित्त पोषण और उच्च व्यक्तिगत ध्यान प्रदान करती हैं।

          यह उन लोगों के लिए है जो मनोरंजन पार्क, आवासीय परिसरों, आतिथ्य स्थलों जैसी विशिष्ट संपत्तियों को खरीदना चाहते हैं।

          डीएन फिनस आपके लिए न केवल सर्वोत्तम ऋण सुनिश्चित करेगा, बल्कि यह भी सुनिश्चित करेगा कि आप इस अनुभव से आत्मविश्वास के साथ बाहर आएं और अपनी संपत्ति के भविष्य के लिए तैयार हों।

          हमारे प्रत्येक विशिष्ट मॉर्गेज कार्यक्रम की विशिष्टताएँ होती हैं, जैसे कि ऋण राशि, अवधि, ब्याज दरें और क्रेडिट स्कोर पात्रता।

          सभी प्रकार की विशिष्ट संपत्ति (होटल, अस्पताल, गोदाम, रिसॉर्ट, कॉलेज, स्कूल, मॉल) के पोस्टर स्क्रॉल करें।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          विशिष्ट संपत्ति ऋण की मुख्य विशेषताएँ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>अनुकूलन समाधान:</strong> ऋण विशेष संपत्ति-संबंधित आवश्यकताओं को पूरा करने के लिए तैयार किए जाते हैं, जिसमें वाणिज्यिक संपत्ति अधिग्रहण और पुनर्वित्त शामिल हैं।</li>
          <li><strong>लचीली शर्तें:</strong> विभिन्न वित्तीय परिस्थितियों के अनुसार लचीली ऋण शर्तें और पुनर्भुगतान विकल्प प्रदान करता है।</li>
          <li><strong>प्रतिस्पर्धी ब्याज दरें:</strong> संपत्ति के प्रकार और उधारकर्ता की प्रोफाइल के आधार पर प्रतिस्पर्धी ब्याज दरों तक पहुंच।</li>
          <li><strong>उच्च ऋण राशि:</strong> संपत्ति के मूल्य और उधारकर्ता की वित्तीय स्थिति के आधार पर बड़ी ऋण राशि प्राप्त करने की संभावना।</li>
          <li><strong>विशेषज्ञ सलाह:</strong> ऋण आवेदन और स्वीकृति प्रक्रिया के दौरान पेशेवर मार्गदर्शन और समर्थन।</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          विशिष्ट संपत्ति ऋण के प्रकार
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>औद्योगिक संपत्ति</li>
          <li>अस्पताल</li>
          <li>होटल</li>
          <li>स्कूल</li>
          <li>विवाह हॉल</li>
          <li>गोदाम</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मापदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>प्रमाणित मूल्यांकक द्वारा संपत्ति का मूल्यांकन</li>
          <li>आय और वित्तीय स्थिरता का प्रमाण</li>
          <li>अच्छा क्रेडिट इतिहास और वित्तीय ट्रैक रिकॉर्ड</li>
          <li>संपत्ति का कानूनी सत्यापन और वैधता प्रमाण</li>
          <li>सम्बंधित क्षेत्र में उधारकर्ता का अनुभव और विशेषज्ञता</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>संपत्ति के कानूनी दस्तावेज़</li>
          <li>प्रमाणित मूल्यांकन रिपोर्ट</li>
          <li>पिछले 2 वर्षों के वित्तीय विवरण</li>
          <li>बैंक स्टेटमेंट</li>
          <li>क्रेडिट स्कोर रिपोर्ट</li>
          <li>उधारकर्ता की पहचान और पते का प्रमाण</li>
        </ul>
      </>
    )
  },
  "वाणिज्यिक अचल संपत्ति ऋण": {
    "title": "वाणिज्यिक अचल संपत्ति ऋण",
    "content": (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* वाणिज्यिक अचल संपत्ति ऋण */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          वाणिज्यिक अचल संपत्ति ऋण (CRE Loan) एक प्रकार का व्यावसायिक ऋण माना जाता है, जो उद्यमियों, व्यवसाय मालिकों और अन्य व्यावसायिक संस्थाओं को विभिन्न व्यावसायिक उद्देश्यों के लिए मशीनरी/उपकरण खरीदने के लिए वित्त प्राप्त करने में मदद करता है। इस प्रकार का ऋण व्यावसायिक संस्थाओं को नई मशीनरी और उपकरणों का उपयोग करते हुए अधिक उत्पादकता प्राप्त करने में मदद करता है।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          लाभ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>यह नए या मौजूदा व्यवसाय के लिए नए उपकरण/मशीनरी खरीदने में मदद करता है।</li>
          <li>मौजूदा मशीनरी/उपकरण को फिर से तैयार करने, संशोधित करने या बदलने के लिए उपयोग किया जाता है।</li>
          <li>खराब मशीनों या उपकरणों की मरम्मत करने या उन्हें उन्नत करने के लिए।</li>
          <li>आसान ईएमआई के साथ लचीले ऋण पुनर्भुगतान विकल्प।</li>
          <li>नए व्यवसायों के लिए स्टार्टअप्स के लिए मशीनरी ऋण एक अतिरिक्त लाभ है।</li>
          <li>चयनित एनबीएफसी, छोटे वित्त बैंक (एसएफबीएस) आदि से बिना गारंटी के ऋण।</li>
        </ul>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginTop: '20px', textAlign: 'left' }}>
          वाणिज्यिक अचल संपत्ति ऋण व्यवसायों को उनकी वाणिज्यिक संपत्तियों में निवेश करने और उन्हें विकसित करने के लिए आवश्यक पूंजी प्रदान करते हैं। लचीली शर्तों और प्रतिस्पर्धी दरों के साथ, ये ऋण व्यावसायिक विकास और विस्तार के लिए आवश्यक हैं।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>उधारकर्ताओं और फर्म या कंपनी के केवाईसी और पासपोर्ट आकार का फोटो।</li>
          <li>उधारकर्ताओं (व्यक्ति या फर्म या कंपनी) के नवीनतम 2 वर्षों के पूर्ण वित्तीय सेट और सीए प्रमाणित कर ऑडिट रिपोर्ट।</li>
          <li>उधारकर्ताओं का नवीनतम 1 वर्ष का बैंक स्टेटमेंट।</li>
          <li>खरीदे जाने वाले मशीनरी के उद्धरण।</li>
          <li>सभी चल रहे पिछले ऋणों का स्वीकृति पत्र (यदि कोई हो)।</li>
        </ul>
      </>
    )
  },
  "ऋण पोर्टफोलियो प्रबंधन सेवाएँ": {
    "title": "ऋण पोर्टफोलियो प्रबंधन सेवाएँ (LPMS)",
    "content": (
      <>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left', marginLeft: '7px' }}>
          डी एन फाइनेंशियल सर्विसेज की ऋण पोर्टफोलियो प्रबंधन सेवाएँ (LPMS) आपको अपने ऋण पोर्टफोलियो को अनुकूलित करने में मदद करेंगी। हमारे व्यापक समाधान आपको अपने ऋण पोर्टफोलियो का प्रभावी ढंग से प्रबंधन और निगरानी करने में सहायता करते हैं, जोखिम को कम करते हैं और पुरस्कारों को अधिकतम करते हैं। शक्तिशाली विश्लेषण और अनुभवी कोचिंग के माध्यम से हम आपके उधार देने की रणनीतियों में सुधार के लिए महत्वपूर्ण अंतर्दृष्टि प्रदान करते हैं। हमारे LPMS के साथ आगे रहें, जो आपकी विशिष्ट आवश्यकताओं के अनुरूप है। डी एन फाइनेंशियल सर्विसेज आपकी ऋण पोर्टफोलियो प्रशासन को सुव्यवस्थित करने में आपकी मदद कर सकता है। आज ही LPMS का अन्वेषण करें और अपनी वित्तीय सफलता को आगे बढ़ाएँ!
          
          इस सेवा में हम आपकी मौजूदा ऋणों का प्रबंधन करते हैं ताकि आपको ब्याज राशि में वृद्धि और ऋण से जुड़ी विभिन्न सुविधाओं से बचाया जा सके, जिससे भविष्य के लिए अधिकतम वित्तीय पात्रता सुनिश्चित हो सके।
          
          हम आपकी प्रोफाइल की नियमित निगरानी और मार्गदर्शन करके वित्तीय बोझ को कम करने में मदद करते हैं ताकि आप भविष्य में अपने संपत्ति निर्माण में आशावादी सुरक्षा के साथ योगदान दे सकें।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          LPMS की मुख्य विशेषताएँ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>पोर्टफोलियो अनुकूलन:</strong> आपके ऋण पोर्टफोलियो के प्रदर्शन को बढ़ाने और प्रतिफल को अधिकतम करने के लिए रणनीतियाँ।</li>
          <li><strong>जोखिम प्रबंधन:</strong> ऋण पोर्टफोलियो से जुड़े संभावित जोखिमों की पहचान और शमन।</li>
          <li><strong>प्रदर्शन ट्रैकिंग:</strong> वित्तीय लक्ष्यों के साथ संरेखण सुनिश्चित करने के लिए ऋण के प्रदर्शन की निगरानी और मूल्यांकन।</li>
          <li><strong>अनुकूलित समाधान:</strong> आपके ऋण पोर्टफोलियो की विशिष्ट आवश्यकताओं को पूरा करने के लिए अनुकूलित सेवाएँ, जिसमें पुनर्गठन और पुनर्वित्त विकल्प शामिल हैं।</li>
          <li><strong>विशेषज्ञ सलाह:</strong> आपके पोर्टफोलियो प्रबंधन को अनुकूलित करने के लिए अनुभवी वित्तीय विशेषज्ञों से पेशेवर मार्गदर्शन और समर्थन।</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          LPMS के लाभ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>ऋण पोर्टफोलियो के बेहतर प्रबंधन और दक्षता</li>
          <li>वित्तीय प्रदर्शन में सुधार और लागत में कमी</li>
          <li>बेहतर जोखिम मूल्यांकन और शमन रणनीतियाँ</li>
          <li>ऋण संपत्तियों पर अधिक पारदर्शिता और नियंत्रण</li>
          <li>विशेषज्ञ सलाह और उन्नत वित्तीय उपकरणों तक पहुंच</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          हमें क्यों चुनें?
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>सफल पोर्टफोलियो प्रबंधन का सिद्ध ट्रैक रिकॉर्ड</li>
          <li>वित्तीय विशेषज्ञों और विश्लेषकों की समर्पित टीम</li>
          <li>ऋण पोर्टफोलियो प्रबंधन के सभी पहलुओं को कवर करने के लिए सेवाओं की व्यापक श्रृंखला</li>
          <li>आपकी विशिष्ट आवश्यकताओं को पूरा करने वाले अनुकूलित समाधान प्रदान करने के प्रति प्रतिबद्धता</li>
          <li>वित्तीय परिणामों को बेहतर बनाने और प्रदर्शन को अनुकूलित करने पर ध्यान केंद्रित</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          शुरुआत करें
        </h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          आज ही हमसे संपर्क करें ताकि आप हमारी ऋण पोर्टफोलियो प्रबंधन सेवाओं के बारे में अधिक जान सकें और हम आपके ऋण पोर्टफोलियो के साथ बेहतर वित्तीय परिणाम प्राप्त करने में आपकी कैसे मदद कर सकते हैं।
        </p>
      </>
    )
  },
  "असुरक्षित व्यावसायिक ऋण": {
    "title": "असुरक्षित व्यावसायिक ऋण",
    "content": (
      <>
        <h1 style={{ fontSize: '24px', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {/* असुरक्षित व्यावसायिक ऋण */}
        </h1>

        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          असुरक्षित व्यावसायिक ऋण बिना किसी गारंटी के वित्तपोषण प्रदान करते हैं। ये ऋण व्यवसायों को विभिन्न उद्देश्यों के लिए लचीले वित्तपोषण विकल्प प्रदान करने के लिए डिज़ाइन किए गए हैं, जैसे कार्यशील पूंजी, विस्तार या परिचालन लागत। वे उन व्यवसायों के लिए एक सुविधाजनक समाधान प्रदान करते हैं जिनके पास सुरक्षा के रूप में गिरवी रखने के लिए पर्याप्त संपत्ति नहीं हो सकती है।
        </p>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          असुरक्षित व्यावसायिक ऋण की प्रमुख विशेषताएँ
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li><strong>कोई गारंटी की आवश्यकता नहीं:</strong> संपत्तियों को सुरक्षा के रूप में प्रदान किए बिना धन प्राप्त करें।</li>
          <li><strong>तेजी से स्वीकृति:</strong> तात्कालिक वित्तपोषण आवश्यकताओं को पूरा करने के लिए त्वरित स्वीकृति और वितरण प्रक्रिया।</li>
          <li><strong>लचीला उपयोग:</strong> धन का उपयोग व्यवसाय की विभिन्न आवश्यकताओं के लिए किया जा सकता है, जिसमें विस्तार, कार्यशील पूंजी और इन्वेंटरी शामिल हैं।</li>
          <li><strong>प्रतिस्पर्धी ब्याज दरें:</strong> उधारकर्ता की क्रेडिट प्रोफाइल और व्यावसायिक प्रदर्शन के आधार पर आकर्षक ब्याज दरें।</li>
          <li><strong>न्यूनतम दस्तावेज़:</strong> न्यूनतम दस्तावेज़ आवश्यकताओं के साथ सरलीकृत आवेदन प्रक्रिया।</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          पात्रता मानदंड
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>कम से कम 1 वर्ष के लिए व्यवसाय संचालन</li>
          <li>सकारात्मक नकदी प्रवाह और वित्तीय स्थिरता</li>
          <li>व्यवसाय के मालिकों का अच्छा क्रेडिट स्कोर</li>
          <li>मान्य व्यवसाय पंजीकरण और लाइसेंस</li>
          <li>हाल के वित्तीय विवरण और आय का प्रमाण</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          आवश्यक दस्तावेज़
        </h2>
        <ul style={{ marginLeft: '20px', lineHeight: '1.6', textAlign: 'left' }}>
          <li>व्यवसाय पंजीकरण दस्तावेज़</li>
          <li>हालिया बैंक स्टेटमेंट</li>
          <li>पिछले 1-2 वर्षों के वित्तीय विवरण</li>
          <li>व्यवसाय मालिकों की पहचान और पते का प्रमाण</li>
          <li>पिछले 1-2 वर्षों के कर रिटर्न</li>
        </ul>

        <h2 style={{ fontSize: '20px', color: '#333', marginBottom: '10px', textAlign: 'left' }}>
          हमारे असुरक्षित व्यावसायिक ऋण क्यों चुनें?
        </h2>
        <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px', textAlign: 'left' }}>
          हमारे असुरक्षित व्यावसायिक ऋण आपके व्यवसाय के लिए वित्तपोषण प्राप्त करने के लिए एक सुव्यवस्थित और लचीला दृष्टिकोण प्रदान करते हैं। त्वरित स्वीकृति प्रक्रिया, न्यूनतम दस्तावेज़, और प्रतिस्पर्धी ब्याज दरों के साथ, हम आपके व्यावसायिक लक्ष्यों और विकास महत्वाकांक्षाओं का समर्थन करने के लिए अनुकूलित वित्तीय समाधान प्रदान करते हैं।
        </p>
      </>
    )
  }
};


const servicesData = localStorage.getItem("language")==="Hindi"?hindiservicesData:englishservicesData
const Services = () => {

  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setIsModalOpen(false);
  };

  const styles = {
    servicesSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '50px 2%',
      gap: '20px',
      backgroundImage: 'linear-gradient(to right, #e52c21, #2862a3)',
      color: '#fff',
      height: 'auto', 
    },
    textContent: {
      maxWidth: '50%',
      textAlign: 'initial',
    },
    textContentH1: {
      fontSize: '38px',
      marginBottom: '20px',
    },
    textContentP: {
      fontSize: '16px',
      lineHeight: '1.6',
    },
    servicesList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
      backgroundColor: '#1e6bb8',
      borderRadius: '15px',
      padding: '40px',
    },
    serviceItem: {
      backgroundColor: '#ffffff',
      color: '#1e6bb8',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '10px',
      fontSize: '18px',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    serviceItemHover: {
      backgroundColor: '#f0f0f0',
    },
    modal: {
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '1000',
    },
    modalContent: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      width: '80%',
      maxWidth: '600px',
      position: 'relative',
      // overflowY: 'auto',
      height: '90%',
      color:'#003c6b',
      overflowY: 'auto',
    },
    modalContentH2: {
      marginTop: '0',
      fontSize: '1.5rem',
      marginBottom: '15px',
    },
    close: {
      position: 'absolute',
      top: '10px',
      right: '10px', // Changed to right for consistency
      fontSize: '1.5rem',
      cursor: 'pointer',
      color: '#333',
    },
    closeHover: {
      color: '#e52c21',
    },
    mobileServicesSection: {
      display: 'block',
      height: 'auto',
      padding: '20px 2%',
      alignItems: 'center',
      backgroundImage: 'linear-gradient(90deg,#e52c21,#2862a3)',
      color: '#fff',
    },
    mobileTextContent: {
      maxWidth: '100%',
      textAlign: 'initial',
    },
    mobileTextContentH1: {
      fontSize: '2rem',
    },
    mobileTextContentP: {
      fontSize: '1rem',
    },
    mobileServicesList: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      gridGap: '20px',
      backgroundColor: '#1e6bb8',
      borderRadius: '15px',
      display: 'grid',
      gap: '20px',
      gridTemplateColumns: 'repeat(2,1fr)',
      padding: '20px'
    },
    mobileServiceItem: {
      cursor: 'pointer',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
      backgroundColor: '#fff',
    borderRadius: '10px',
    color: '#1e6bb8',
    fontSize: '18px',
    fontWeight: '700',
    padding: '20px',
    textAlign: 'center',
    },
    mobileServiceItemHover: {
      backgroundColor: '#e0e0e0',
    },
  };

  return (
    <section 
      style={window.innerWidth <= 768 ? styles.mobileServicesSection : styles.servicesSection}
    >
      <div 
        style={window.innerWidth <= 768 ? styles.mobileTextContent : styles.textContent}
      >
        <h1 style={window.innerWidth <= 768 ? styles.mobileTextContentH1 : styles.textContentH1}>
        {localStorage.getItem("language")==="Hindi"?"हमारी सेवाएँ":"Our Services"}
        </h1>
        {localStorage.getItem("language") === "Hindi" ?
          <p style={window.innerWidth <= 768 ? styles.mobileTextContentP : styles.textContentP}>
            <p>
          डीएनफिनएस में, हम आपकी विशेष जरूरतों को पूरा करने के लिए विभिन्न प्रकार के वित्तीय समाधान प्रदान करते हैं। हमारी सेवाओं में शामिल हैं: घर खरीदने या नवीनीकरण के लिए होम लोन, आपकी संपत्तियों का लाभ उठाने के लिए संपत्ति के बदले ऋण, उच्च मूल्य की खरीदारी और अनोखी वित्तीय आवश्यकताओं के लिए विशेष संपत्ति ऋण, बिना संपार्श्विक के लचीले व्यापार वित्तपोषण के लिए असुरक्षित व्यवसायिक ऋण, और आपके ऋण पोर्टफोलियो को प्रभावी ढंग से प्रबंधित और अनुकूलित करने के लिए ऋण पोर्टफोलियो प्रबंधन सेवाएं (एलपीएमएस)। हमारी विशेषज्ञ टीम व्यक्तिगत समर्थन और नवीन समाधान प्रदान करने के लिए समर्पित है, जिससे आप आसानी से अपने वित्तीय लक्ष्यों को प्राप्त कर सकें।
        </p>
          </p>
          :
          <p style={window.innerWidth <= 768 ? styles.mobileTextContentP : styles.textContentP}>
        <p style={{ fontSize: '16px', lineHeight: '1.6', textAlign: 'left' }}>
        At DNFinS, we offer a diverse range of financial solutions tailored to meet your unique needs. Our services include Home Loans for purchasing or renovating residential properties, Loan Against Property for leveraging your assets, Specialized Property Loans for high-value purchases and unique financing requirements, Unsecured Business Loans for flexible business funding without collateral, and Loan Portfolio Management Services (LPMS) to optimize and manage your loan portfolio effectively. Our expert team is dedicated to providing personalized support and innovative solutions to help you achieve your financial goals with ease.
      </p>   </p>
        }
      </div>
      <div 
        style={window.innerWidth <= 768 ? styles.mobileServicesList : styles.servicesList}
      >


        


        {Object.keys(servicesData).map(service => (
          <div 
            key={service} 
            style={window.innerWidth <= 768 ? styles.mobileServiceItem : styles.serviceItem}
            onClick={() => openModal(service)}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = window.innerWidth <= 768 ? '#e0e0e0' : '#f0f0f0'}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = window.innerWidth <= 768 ? '#f0f0f0' : '#ffffff'}
          >
            {service}
          </div>
        ))}
      </div>

      {isModalOpen && selectedService && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <span 
              style={styles.close}
              onClick={closeModal}
              onMouseOver={(e) => e.currentTarget.style.color = '#e52c21'}
              onMouseOut={(e) => e.currentTarget.style.color = '#333'}
            >
              &times;
            </span>
            <h2 style={styles.modalContentH2}>{servicesData[selectedService].title}</h2>
            {servicesData[selectedService].content}
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;

