import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from './logo.jpg'; // Ensure the logo image is in the correct directory
import promotion from './../../assets/images/promotion1.jpg';
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaGoogle,
} from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const Header = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 'auto', height: 'auto' });

  useEffect(() => {
    // Open the dialog when the page loads
    setOpenDialog(true);

    // Preload the image and set its natural dimensions
    const img = new Image();
    img.src = promotion;
    img.onload = () => {
      setImageDimensions({
        width: img.naturalWidth > window.innerWidth ? '90%' : `${img.naturalWidth}px`,
        height: img.naturalHeight > window.innerHeight ? '90vh' : 'auto',
      });
    };
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const changeLanguage = () => {
    if (localStorage.getItem('language') === 'Hindi') {
      localStorage.setItem('language', 'English');
    } else {
      localStorage.setItem('language', 'Hindi');
    }
    location.reload();
  };

  return (
    <>
      <header className="header">
        <div className="left-side">
          <img src={logo} alt="DnFins Logo" className="logo" />
        </div>
        <div className="right-side">
          <button className="buttononHeader" onClick={changeLanguage}>
            {localStorage.getItem('language') === 'Hindi' ? 'English' : 'हिंदी'}
          </button>
          <div className="social-media-links" style={{ marginBottom: '-10px' }}>
            <a
              href="https://www.facebook.com/share/SKRje6VGyUun1ur2/?mibextid=qi2Omg"
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaFacebook />
            </a>
            <a
              href="https://g.co/kgs/FWVA9Ez"
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaGoogle />
            </a>
            <a
              href="https://www.linkedin.com/company/dnfins/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.instagram.com/dnfinancial.services?igsh=OTh0NXhhcWUyMmMw"
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </header>

      {/* Material-UI Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: imageDimensions.width,
            height: imageDimensions.height,
            margin: 'auto',
            maxHeight: '90vh',
            overflow: 'hidden',
          },
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            margin: 0,
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <img
            src={promotion}
            alt="Promotion"
            style={{
              margin: 0,
              padding: 0,
              width: '100%',
              height: 'auto',
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
