import React from 'react';
import Corousal from "./../components/Carousal/Carousal";
import AnimatedSection from "./../components/InformationSection/InformationSection"
import Services from "./../components/Services/Services"
import ContactUs from "./../components/ContactUs/ContactUs"
import ProductHighlight from "./../components/Product/ProductHighlight"
import ProductHighlight1 from "./../components/Product2/ProductHighlight"
import Calculator from "./../components/Calculator/FinancialCalculator"
import CarouselCard from "./../components/CarousalCards/Carousel"
import ReviewsCarousel from "./../components/ReviewsCarousal/ReviewsCarousel"
import Partners from "./../components/ReviewsCarousal/Partners"
const Homepage = () => {
  return (
   <div className='carousal-container'>
    <Corousal />
    <AnimatedSection />
    <Services />
    <ProductHighlight />
    <ProductHighlight1 />
    <Calculator />
    <CarouselCard />
    <Partners />
    <br></br>
     <ReviewsCarousel />
    {/* <ContactUs /> */}
    </div>
  );
};

export default Homepage;
