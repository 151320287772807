import "./styles.css";
import Header from './../src/components/Header/Header';
import Homepage from './pages/Homepage'
import Footer from './../src/components/Footer/Footer'
export default function App() {
  return (
    <div className="App">
     <Header />
     <Homepage />
     <Footer />
    </div>
  );
}
