import React from 'react';
import './CardComponent.css';

const CardComponent = ({ imageSrc, title, description, url }) => {
    const handleCardClick = (url) => {
        window.open(url, '_blank');
      };
  return (
    <div className="card" onClick={() => handleCardClick(url)}>
      <img src={imageSrc} alt={title} className="card-image" />
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-description">{description}</p>
        <button className="card-button">{localStorage.getItem("language")==="Hindi" ?"अधिक जानकारी": "KNOW MORE"}</button>
      </div>
    </div>
  );
};

export default CardComponent;
