import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Informationsection.css';
import image1 from './../../assets/images/AboutUs.jpeg';
import image2 from './../../assets/images/topImage.png';

const AnimatedSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1500 },
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="section-container">
      <animated.div style={fadeIn}>
        <div className="image-container">
          <img src={image1} alt="Image 1" className="section-image" />
          <div>
            <h1 className="section-title">
              {localStorage.getItem("language") === "Hindi" ? "हमारे बारे में" : "About Us"}
            </h1>
            {localStorage.getItem("language") === "Hindi" ? (
              <p className="section-text">
                <div>
                  <p>
                    1993 में, स्वर्गीय श्री डी एन सिंघल द्वारा सेवा के क्षेत्र में एक दृष्टिकोण की स्थापना की गई थी। डीएन ने कुशल युवाओं को सशक्त किया और गुणवत्ता सेवाओं को सुनिश्चित किया। एक ऑटोमोबाइल सेवा केंद्र के रूप में शुरू होकर, डीएन 1996 में मारुति अधिकृत सेवा केंद्र बन गया और प्रमुख बीमा कंपनियों से पहचान प्राप्त की। 2004 में, डीएन ने भारती एयरटेल लिमिटेड के साथ ब्रॉडबैंड और टेलीफोन सेवाओं के लिए साझेदारी की और सर्वश्रेष्ठ सेवा प्रदाता का पुरस्कार जीता। डीएन ने टाटा स्काई लिमिटेड, रिलायंस कम्युनिकेशन, और टेलीसेनिक नेटवर्क्स के साथ भी सहयोग किया।
                  </p>
                  {isExpanded && (
                    <>
                      <p>
                        विभिन्न क्षेत्रों में अपने व्यापक अनुभव का लाभ उठाते हुए, डीएन ने सफलता के नए अवसरों की पहचान की। 2019 में, कंपनी ने बंधक वित्तीय सेवाओं में कदम रखा और श्री अरविंद सिंघल (NIT से बी.टेक, एल.एल.बी, एम.बी.ए, और CREA, कनाडा से लाइसेंस प्राप्त रियाल्टर) को पेश किया, जिनके पास 15 से अधिक वर्षों का अनुभव है, और श्रीमती पूजा झा (बी.कॉम, एम.बी.ए) को पेश किया, जिनके पास बैंकिंग और वित्त में 10 से अधिक वर्षों का अनुभव है।
                      </p>
                      <span>
                        आज, "डीएनफिनएस लोन की पाठशाला" के तहत, डीएन 50 से अधिक बैंकों और एनबीएफसी के साथ एक अधिकृत चैनल पार्टनर के रूप में अपने क्षेत्र का विस्तार कर रहा है, और अपने ग्राहकों को पूरी जिम्मेदारी और समर्पण के साथ सेवा प्रदान कर रहा है।
                      </span>
                    </>
                  )}
                </div>
              </p>
            ) : (
              <p className="section-text">
                <div>
                  <p>
                  In the year of 1993 a vision in the field of service was founded by Late Shri D N Singhal, DN empowers skilled youth and ensures quality services. Starting as an automobile service center, DN became a MARUTI Authorized Service Centre in 1996 and earned recognition from top insurance companies. In 2004, DN partnered with Bharti Airtel Ltd. for broadband and telephone services, winning awards as the Best Service Provider. DN also collaborated with Tata Sky Ltd., Reliance Communication, and Telesonic Networks.
                  </p>
                  {isExpanded && (
                    <>
                      <p>Leveraging its extensive experience across various fields, DN identified new opportunities for success. In 2019, the company ventured into mortgage financial services, introducing Mr. Arvind Singhal (B.Tech from NIT, L.L.B, M.B.A, and Licensed Realtor from CREA, Canada) with over 15 years of experience, and Ms. Pooja Jha (B.Com, M.B.A) with over 10 years of expertise in banking and finance.</p>
                      <span>Today, under the banner of "DNFinS Loan ki Pathshala," DN is expanding its horizons as an authorized Channel Partner with over 50 Banks and NBFCs, serving clients with utmost responsibility and dedication.</span>
                    </>
                  )}
                </div>
              </p>
            )}
            <button className="read-more-button" onClick={toggleExpand}>
              {isExpanded ? (localStorage.getItem("language") === "Hindi" ? "कम पढ़ें" : "Read Less") : (localStorage.getItem("language") === "Hindi" ? "अधिक पढ़ें" : "Read More")}
            </button>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default AnimatedSection;
