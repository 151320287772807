import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hdfc from './../../assets/images/hdfc.png';
import federal from './../../assets/images/federal.png';
import pnb from './../../assets/images/pnb.png';
import icici from './../../assets/images/icici.png';
import bajaj from './../../assets/images/bajaj.png';
import icicF from './../../assets/images/iciciF.png';

const partners = [
  { src: hdfc, alt: 'ICICI Bank', category: '' },
  { src: federal, alt: 'ICICI Home Finance', category: '' },
  { src: bajaj, alt: 'PNB Housing', category: '' },
  { src: pnb, alt: 'Federal Bank', category: '' },
  { src: icici, alt: 'HDFC Bank', category: '' },
  { src: icicF, alt: 'Axis Bank', category: '' },
];

const ChannelPartners = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow:  window.screen.availWidth<769 ?3:5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div style={{ textAlign: 'center', padding: window.screen.availWidth<769 ?'26px' :'20px' }}>
      <h2 style={{ fontSize: '2em', marginBottom: '20px' }}>Our Channel Partners</h2>
      <Slider {...settings}>
        {partners.map((partner, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={partner.src} alt={partner.alt} style={{ maxWidth: '100px', height: 'auto' }} />
            <p style={{ marginTop: '10px', fontSize: '1em' }}>{partner.category}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ChannelPartners;
